<template>
  <div
    ref="container"
    class="survey__container fixed bottom-0 right-0 z-[990] p-3 max-w-full sm:max-w-[450px] w-[450px] transition-all drop-shadow-2xl"
  >
    <div
      v-show="showTopbar"
      class="survey__topbar flex items-center p-4 bg-gradient-to-r from-error to-[#ec6446] min-h-14 rounded-t-2xl"
    >
      <span
        v-if="isMinimised"
        id="survey-title"
        v-track-click
        class="block text-white text-lg w-full hover:cursor-pointer"
        @click="handleMinimise"
      >
        {{ title }}
      </span>

      <div class="topbar__buttons flex ml-auto">
        <button id="survey-minimise" v-track-click @click="handleMinimise">
          <t-icon
            v-show="!isMinimised"
            class="ml-2"
            :icon="mdiMinus"
            color="white"
          />
        </button>

        <button id="survey-close" v-track-click @click="handleClose">
          <t-icon
            v-show="!isMinimised"
            class="ml-2"
            :icon="mdiClose"
            color="white"
          />
        </button>

        <button id="survey-expand" v-track-click @click="handleMinimise">
          <t-icon
            v-show="isMinimised"
            size="32"
            class="ml-2"
            :icon="mdiChevronUp"
            color="white"
          />
        </button>
      </div>
    </div>

    <div class="survey__body max-h-[700px] bg-white rounded-b-xl">
      <div ref="filloutContainer" />
    </div>
  </div>
</template>

<script>
import { EventBus } from 'chimera/all/plugins/eventbus'
import MobileDetect from 'mobile-detect'
import { mdiChevronUp, mdiClose, mdiMinus } from '@mdi/js'

export default {
  props: {
    id: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    parameters: {
      type: Object,
      default: () => ({}),
    },
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      showCloseButton: false,
      showMinimiseButton: false,
      showTopbar: false,
      isMinimised: false,
      isMobile: false,
      mdiChevronUp,
      mdiClose,
      mdiMinus,
    }
  },

  /**
   *
   */
  beforeMount() {
    const md = new MobileDetect(window.navigator.userAgent)
    this.isMobile = md.mobile()
  },

  /**
   */
  mounted() {
    if (this.isMobile) {
      this.showCloseButton = true
      this.showMinimiseButton = true
      this.handleMinimise()
    }

    this.embedFillout(this.parameters)
  },

  methods: {
    /**
     */
    handleMinimise() {
      this.isMinimised = !this.isMinimised
      this.$refs.container?.classList.toggle('minimised')
    },

    /**
     */
    handleClose() {
      this.$refs.container?.remove()
    },

    /**
     * @param {object} parameters
     */
    embedFillout(parameters = {}) {
      if (!this.id || !this.$refs.filloutContainer) {
        return
      }

      const filloutContainer = this.$refs.filloutContainer
      Object.assign(filloutContainer.dataset, {
        filloutId: this.id,
        filloutEmbedType: 'standard',
        filloutInheritParameters: '',
        filloutDynamicResize: '',
        filloutDomain: 'feedback.bambelo.com',
        ...parameters,
      })

      const script = document.createElement('script')
      script.src = 'https://server.fillout.com/embed/v1/'
      script.onload = () => {
        this.showTopbar = true

        setTimeout(() => {
          this.showCloseButton = true
          this.showMinimiseButton = true
        }, 1000)
      }
      script.onerror = () => {
        EventBus.emitWarningAppErrorEvent('Script failed to load', {
          filloutSurveyId: this.id,
        })
      }

      document.body.appendChild(script)
    },
  },
}
</script>

<style scoped lang="scss">
.minimised {
  @apply pb-0 #{!important};

  .survey__body {
    @apply hidden;
  }
}
</style>
