<template>
  <div />
</template>

<script>
import AbstractPage from 'chimera/all/components/page/AbstractPage.vue'
import FilloutSurveyPd7952 from 'chimera/all/components/FilloutSurvey.PD7952.vue'
import { EventBus } from 'chimera/all/plugins/eventbus'
import { ExperimentSource } from 'chimera/all/mixins/usesExperiments'
import Vue from 'vue'

export default {
  name: 'AbstractHomePage',

  extends: AbstractPage,

  /**
   *
   */
  created() {
    this.$store.dispatch('configuration/resetLandingPage')
  },

  /**
   * @returns {Promise<void>}
   */
  async mounted() {
    await this.handlePD7952Experiment()
  },

  methods: {
    /**
     * @returns {Promise<void>}
     */
    async handlePD7952Experiment() {
      const experiment = await this.$store.dispatch('experiments/get', {
        id: 'PD-7952',
        source: ExperimentSource.ABSMARTLY,
      })

      const variantValues =
        Vue.prototype.$absmartly.variableValue(
          'surveyConfigurationPD7952',
          {},
        ) ?? {}

      if (!experiment?.isTreatment || !variantValues) {
        return
      }

      const locale = this.$i18n.locale
      const surveyConfiguration = variantValues[locale]
      if (!surveyConfiguration) {
        EventBus.emitErrorAppErrorEvent(
          new Error(
            `Could not run experiment. Survey configuration not found for locale: ${locale}`,
          ),
        )
        return
      }

      const context = this.$store.getters['context/get']
      const parameters = {
        label: this.concept,
        locale,
        session_id: context('sessionId'),
        visitor_id: context('visitorId'),
        device_type: context('deviceType'),
        url: window.location.href,
      }

      this.runHomepageSurveyPD7952(
        surveyConfiguration.id,
        surveyConfiguration.title,
        parameters,
      )
    },

    /**
     * @param { string } id
     * @param { string } title
     * @param { object } parameters
     */
    runHomepageSurveyPD7952(id, title, parameters = {}) {
      const surveyElementId = `fillout-survey-pd7952-${id}`
      if (document.getElementById(surveyElementId)) {
        return
      }

      const FilloutSurvey = Vue.extend(FilloutSurveyPd7952)
      const instance = new FilloutSurvey({
        propsData: {
          id,
          title,
          parameters,
        },
      })

      instance.$mount()
      instance.$el.id = surveyElementId

      document.getElementById('app').appendChild(instance.$el)
    },
  },
}
</script>
