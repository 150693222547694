<template>
  <div class="page-index">
    <Header background-image="images/hero.jpg">
      <h2 class="header__title">Vind dé specialist in jouw regio</h2>

      <h3 class="header__subtitle">
        Vergelijk offertes voordat je beslist en bespaar tot 40%!
      </h3>

      <TCard class="header__card">
        <div class="card__form">
          <PostalFormStep :progress-value="0" />
        </div>
      </TCard>

      <span class="header__subtext">
        Vergelijk 6 accountantskantoren in jouw regio
      </span>
    </Header>

    <HomeBlock id="usps">
      <Usps :usps="usps" />
    </HomeBlock>
  </div>
</template>

<script>
import AbstractHomePage from 'chimera/all/components/page/AbstractHomePage'
import HomeBlock from 'chimera/all/components/HomeBlock'
import Usps from 'chimera/all/themes/blueflow/components/Usps'
import Header from 'chimera/all/themes/blueflow/components/layout/Header'
import PostalFormStep from '~/components/form/steps/postal/PostalFormStep'

export default {
  components: {
    Header,
    HomeBlock,
    PostalFormStep,
    Usps,
  },

  extends: AbstractHomePage,

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Vergelijk alle specialisten',
      headDescription:
        'Vergelijk vakkundige bedrijven op Accountantvergelijker.nl. Snel, betrouwbaar en alleen offertes uit jouw regio. Bespaar tot wel 40%.',
      path: '/',
      usps: [
        {
          title: 'Beantwoord een paar vragen',
          text: 'Je vertelt ons wat je zoekt, hierdoor kunnen wij de juiste accountantskantoren uit jouw regio aan jou koppelen.',
          logo: require('chimera/all/themes/blueflow/images/usps/pencil.svg'),
        },
        {
          title: 'Ontvang gratis offertes',
          text: 'Je ontvangt maximaal zes offertes van accountantskantoren die aan je behoeften voldoen. Hiermee bespaar je tot 40%!',
          logo: require('chimera/all/themes/blueflow/images/usps/offer.svg'),
        },
        {
          title: 'Kies de juiste specialist',
          text: 'Wij hebben onze specialisten al gecheckt, je hoeft alleen maar de offertes te vergelijken en te kiezen.',
          logo: require('chimera/all/themes/blueflow/images/usps/user.svg'),
        },
      ],
    }
  },
}
</script>
